<template>
    
    <div>
    
          <local-projects-top-statement /> <!-- Links to src/local/projects/overview.vue -->
      
      <v-container>
          <local-projects-overview /> <!-- Links to src/local/projects/overview.vue -->
      </v-container>

      <v-card-text/>
      <v-divider/>
      <v-card-text/>

      <v-container>
          <local-projects-mid-statement /> <!-- Links to src/local/projects/overview.vue -->
      </v-container>

      <v-container>
        
        <v-container>
            <local-projects-card-right project='aws' /> <!-- Links to src/local/projects/card-right.vue -->
        </v-container>

        <v-container>
            <local-projects-card-left project='azure' /> <!-- Links to src/local/projects/card-right.vue -->
        </v-container>
        
        <v-container>
            <local-projects-card-right project='sap' /> <!-- Links to src/local/projects/card-right.vue -->
        </v-container>
      
      </v-container>

    </div>

</template>


<script>

  export default {
    name: 'Projects',
    data() {
      return {
        pagename: 'projects'
      }
    }
  }
</script>
